<script lang="jsx">
import UseTouch from './useTouch'
import { startCountdown } from './timer.js'

export default {
  name: 'SlideSwiper',
  props: {
    direction: {
      type: String,
      default: 'ltr',
    },
    itemLength: { // item的个数
      type: Number,
      default: 0,
    },
    observerLength: { // 是否监听item的个数
      type: Boolean,
      default: false,
    }, 
    spaceBetween: { // 每个item间距（单位px）
      type: Number,
      default: 0, 
    },
    transitionDuration: {     // 过渡动画ms;
      type: Number, 
      default: 300,
      required: false,
    },
    additionalX: { // 最大滑动距离
      type: Number,
      default: 200,
      required: false,
    },
    expectedDistance: { // 滑动x距离，就会滑到下一个
      type: Number,
      default: 20,
      required: false,
    },
    touchable: { // 是否可以滑动
      type: Boolean,
      default: true,
    },
    loop: {   // 循环滚动（到最后一个，会滚动到第一个）
      type: Boolean,
      default: false,
    },
    autoplay: {   // 自动播放多久开始滚动(单位ms)
      type: Number,
      default: 0,
    },
    firstAutoplay: {  // 第一次自动播放多久开始滚动(单位ms)
      type: Number,
      default: 0,
    },
    vertical: Boolean, // 垂直滚动
    stopPropagation: Boolean, // 是否阻止滑动事件冒泡
    slidesPerView: {
      type: String,
      default: '',  // auto 自动计算，每一个item宽度不一致时传递这个
    },
    delayLoopInitTime: { // 延迟多久开始初始化
      type: Number,
      default: 0,
    },
    intersectionObserver: {   // 是否需要只在可视时才自动轮播
      type: Boolean,
      default: false
    },
    freeMode: {
      type: Boolean,
      default: false
    }
  },
  provide() {
    return {
      spaceBetween: this.spaceBetween,
      direction: this.direction,
      vertical: this.vertical,
    }
  },

  data() {
    return {
      translateX: 0,
      translateY: 0,
      wrapperWidth: 0,
      viewAreaWidth: 0, // 视图的宽度
      viewAreaHeight: 0,
      racewayDistanceHeight: 0, // 可滑动高度距离
      racewayDistanceWidth: 0, // 可滑动宽度距离

      touching: false, // 是否正在滑动
      itemTranslateLists: [], // item的宽度
      dynamicDuration: undefined, // 动态设置过渡时间
      autoIndex: 1, // 自动播放的索引

      disabledAutoPlay: false, // 禁止自动播放
      newIndex: 0,

      autoplayStart: null,
      autoplayStop: null,

      IntersectionObserverInstance: null
    }
  },

  computed: {
    wrapStyle() {
      const time = typeof this.dynamicDuration === 'undefined' ? this.transitionDuration : this.dynamicDuration
      const Style = time === 0 ? {} : {
        willChange: 'auto', // 优化滚动性能 
        transitionDuration: this.touching ? '0ms' : `${time}ms`,
      }
      if (this.vertical) {
        Object.assign(Style, {
          transform: `translate3d(0px, ${this.translateY}px, 0px)`,
          flexFlow: 'column nowrap',
          width: '100%',
        })
      } else {
        Object.assign(Style, {
          transform: `translate3d(${this.translateX}px, 0px, 0px)`,
          flexFlow: 'row nowrap',
          height: '100%',
        })
      }
      return Style
    },

    isLft() {
      return this.direction === 'ltr'
    }
  },

  watch: {
    itemLength(newVal, oldVal) { // 更新宽度
      if (!this.observerLength) return
      if (newVal !== oldVal) {
        // 注意： 判断当前组件是否显示在页面,没有显示在页面，监听到显示在页面，进行更新
        this.$nextTick(() => {
          this.onUpdate()
        })
      }
    }
  },

  mounted() {  
    const target = this.$refs.slideSwiper
    target.addEventListener('touchmove', this.handleTouchMove, { passive: false })
    this.$once('hook:beforeDestroy', () => {
      target.removeEventListener('touchmove', this.handleTouchMove)
    })

    if (this.loop) {
      this.delayLoopTimer = setTimeout(() => {
        this.$refs.slideSwiper && this.handleInit()
      }, this.delayLoopInitTime)
    }
  },

  beforeDestroy() {
    this.onAutoplayStop()
  },

  methods: {
    handleLoopInit() {
      if (!this.loop || !this.autoplay) return

      if (!this.autoplayStart) {
        this.handleLoopCreateElement()
        if (this.disabledAutoPlay) {
          this.autoplayStart = ({ type } = {}) => {
            if (type === 'observer') {
              this.$emit('loopPlayStart', 0, 0)
            }
          }
          this.autoplayStop = () => {}  
        } else {
           // 清空之前的定时器
          if (this.autoplayCancel) {
            this.autoplayCancel()
            if (this.IntersectionObserverInstance) {
              this.autoIndex = 1
              this.IntersectionObserverInstance?.disconnect?.() // 断开之前的监听
              this.IntersectionObserverInstance = null
              this.slideTo(this.autoIndex, 0, true)
            }
          }

          const { start, stop, cancel } = startCountdown({
            time: this.autoplay,
            autoplay: true,
            onEnd: () => {
              const afterIndex = this.autoIndex + 1

              const slideIndex = afterIndex - 2
              this.$emit('loopPlayStart', slideIndex, this.loopIndex)

              this.slideTo(afterIndex, this.transitionDuration, true)
            }
          })
          this.autoplayStart = () => {
            this.loopIndex = 0
            start()
          }
          this.autoplayStop = stop
          this.autoplayCancel = cancel
        }
      }

      if (this.loop && this.intersectionObserver) {
        this.initIntersectionObserverInstance()
      } else {
        this.onAutoPlayStart()
      }
    },

    onAutoPlayStart() {
      // const params = {}
      // if (this.firstAutoplay) {
      //   let firstTime = this.delayLoopInitTime && !this.initCalced ? this.firstAutoplay - this.delayLoopInitTime : this.firstAutoplay  // 延迟多久开始自动播放, 需要减去延迟初始化的时间
      //   this.initCalced = true  
      //   if (firstTime < 0) {
      //     firstTime = 0
      //   }
      //   params.firstTime = firstTime
      // }
      // this.autoplayStart?.(params)
      this.autoplayStart?.()
    },

    onAutoplayStop() {
      this.autoplayStop && this.autoplayStop()
    },
    handleLoopCreateElement() {
      const slides = this.wrapperEl.children // 所有子元素
      if (slides.length === 1) { // 只有一个元素，不需要循环
        this.disabledAutoPlay = true
        return
      }

      const el = slides[0]
      const lastEl = slides[slides.length - 1]
      const firstSlide = el.cloneNode(true)
      const lastSlide = lastEl.cloneNode(true)
      
      this.dynamicDuration = 0 // 关闭过渡动画

      if (this.vertical) {
        this.translateY = -(el.getBoundingClientRect().height)
      } else {
        this.translateX = (el.offsetWidth + this.spaceBetween) * (this.isLft ? -1 : 1)
        if (this.spaceBetween) {
          lastSlide.style[this.getDirectionLabel('marginLeft')] = 0
          lastSlide.style[this.getDirectionLabel('marginRight')] = `${this.spaceBetween}px`
          firstSlide.style[this.getDirectionLabel('marginLeft')] = `${this.spaceBetween}px`
        }
      }

      firstSlide.addEventListener('click', () => {
        el.click()
      }, { passive: false })

      lastSlide.addEventListener('click', () => {
        lastEl.click()
      }, { passive: false })

      this.wrapperEl.appendChild(firstSlide)
      this.wrapperEl.insertBefore(lastSlide, this.wrapperEl.firstChild)
    },

    /**
     * @params {Number} index 滑动到第几个
     * @params {Number} duration 滑动时间
     * @params {Boolean} isSlideChangeTransitionEnd 是否触发滑动结束事件
    */
    async slideTo(index, duration, isSlideChangeTransitionEnd = true, onTransitionEnd) {
      await this.handleInit() // 防止外部调用，没有触摸事件，进行切换的bug

      if (!this.itemTranslateLists.length) {
        // 关闭定时器
        this.onAutoplayStop()
        // throw new Error('itemTranslateLists is empty')
      }

      if (typeof duration !== 'undefined' && typeof duration !== 'number') {
        throw new Error('duration must be a number')
      }

      this.autoIndex = index
      
      if (index < 0) {
        index = 0
      }
      if (index > this.itemTranslateLists.length - 1) {
        index = this.itemTranslateLists.length - 1
      }

      if (typeof duration === 'number') {
        this.dynamicDuration = duration
        let activeIndex = index - 1 
        if (activeIndex >= this.itemLength) {
          activeIndex = 0
        }
        this.$emit('activeIndexChange', activeIndex, index)
        setTimeout(() => {
          this.dynamicDuration = void 0

          this.changeLoopSlideDone(index)
          onTransitionEnd && onTransitionEnd()

          if (isSlideChangeTransitionEnd) {
            if (!this.loop) {
              this.changeSlideChangeTransitionEnd(index)
            } else {
              let newCurrentIndex = index - 1 
              if (newCurrentIndex >= this.itemLength) {
                newCurrentIndex = 0
              }
              this.changeSlideChangeTransitionEnd(newCurrentIndex)
            }
          }
        }, duration || 50)
      }

      let currentIndexMovePlan = this.itemTranslateLists[index]
      const maxRacePlan = this.vertical ? this.racewayDistanceHeight : this.racewayDistanceWidth
      const beyondSlideArea = currentIndexMovePlan > maxRacePlan
      if (beyondSlideArea) { // 防止最后一项，超出可滑动的距离
        currentIndexMovePlan = maxRacePlan
      }

      if (this.vertical) {
        this.translateY = -currentIndexMovePlan
      } else {
        const slideX = this.isLft ? -currentIndexMovePlan : currentIndexMovePlan
        if (this.freeMode) {
          if (beyondSlideArea || slideX === 0) {
            this.translateX = slideX
          }
        } else {
          this.translateX = slideX
        }
      }
    },

    changeLoopSlideDone(index) {
      if (!this.loop) return

      if (index >= this.itemLength + 1) { // 循环的话，判断最后一项
        this.loopIndex ++

        this.dynamicDuration = 0
        this.autoIndex = 1
        if (this.vertical) {
          this.translateY = -this.itemTranslateLists[1]
        } else {
          this.translateX = this.isLft ? -this.itemTranslateLists[1] : this.itemTranslateLists[1]
        }
      }

      if (index <= 0) { // 循环的话，判断第一项
        this.dynamicDuration = 0
        this.autoIndex = this.itemLength
        if (this.vertical) {
          this.translateY = -this.itemTranslateLists[this.itemLength]
        } else {
          this.translateX = this.isLft ? -this.itemTranslateLists[this.itemLength] : this.itemTranslateLists[this.itemLength]
        }
      }
    },

    changeSlideChangeTransitionEnd(index) {
      this.$emit('slideChangeTransitionEnd', index)
      if (this.newIndex === index) return
      this.newIndex = index
    },

    onTouchStart(e) {
      if (!this.touchable || e.touches.length > 1) return
      this.touching = true
      if (!this.touch) {
        this.touch = new UseTouch({ stopPropagation: this.stopPropagation, vertical: this.vertical })
      }
      this.changeLoopSlideDone(this.autoIndex)
      this.handleInit()
      this.autoplayStop && this.autoplayStop() // 停止播放，必须放在初始化后面，否则会出现bug
      this.touch.start(e)
    },

    handleTouchMove(e) {
      if (!this.touchable || e.touches.length > 1) return
      
      this.touch.move(e)

      if (this.vertical) {
        this.changeVerticalMove()
      } else if (this.touch.isHorizontal()) {
        this.changeHorizontaMove()
      }
    },

    changeVerticalMove() {
      let outY = 0 // 超出的距离，值越大，滑动越慢

      if (this.translateY > 0) {
        outY = Math.abs(this.translateY)
      } else {
        const remainY = this.translateY + this.racewayDistanceHeight
        if (remainY < 0 ) {
          outY = Math.abs(remainY)
        }
      }

      let slideDistance = this.touch.moveY // 正常滑动
      if (!this.loop && outY > 0) {
        // 最大滑动距离  / （可视化宽度 + 当前超出的距离) 随着超出的距离越大，滑动的距离越小
        slideDistance = (this.additionalX * this.touch.moveY) / ((outY + this.viewAreaHeight))
      }

      this.translateY += slideDistance
    },

    changeHorizontaMove() {
      let outX = 0 // 超出的距离，值越大，滑动越慢

      if (this.isLft) { // 正常布局
        if (this.translateX > 0) {
          outX = Math.abs(this.translateX)
        } else {
          const remainX = this.translateX + this.racewayDistanceWidth
          if (remainX < 0 ) {
            outX = Math.abs(remainX)
          }
        }
      } else {  // 反方向布局（ar站点）
        if (this.translateX < 0) {
          outX = Math.abs(this.translateX)
        } else {
          const remainX = this.translateX - this.racewayDistanceWidth
          if (remainX > 0) {
            outX = remainX
          }
        }
      }
      
      let slideDistance = this.touch.moveX // 正常滑动
      if (outX > 0) {
        // 最大滑动距离  / （可视化宽度 + 当前超出的距离) 随着超出的距离越大，滑动的距离越小
        slideDistance = (this.additionalX * this.touch.moveX) / ((outX + this.viewAreaWidth))
      }
      this.translateX += slideDistance
      this.$emit('slideMove', {
        moveX: this.translateX,
        direction: this.touch.getIsMoveLeft() ? 'left' : 'right'
      })
    },

    onTouchEnd() {
      if (!this.touchable) return
      this.touching = false
      this.$emit('touchEnd')
      if (this.vertical) {
        this.handleMoveVerticalEnd()
      } else if (this.touch.isHorizontal()) {
        this.handleMoveHorizontaEnd()
      }
    },

    // 垂直方向： 处理当前item滚动的距离是否展示完全
    handleMoveVerticalEnd() {
      let y = 0
      y = this.translateY > 0 ? 0 : Math.abs(this.translateY)

      // 1.先找到当前滑动的item索引上
      // 2.在判断是往前还是往后移动一个
      if (this.slidesPerView !== 'auto') {
        let currentIndex = 0
        this.itemTranslateLists.some((item, index) => {
          if (item > y) return true
          currentIndex = index
        })
        this.slideChangeRuleHeight(currentIndex, y)
      } else {
        // todo: 不规则的item
      }
    },

    // 水平方向： 处理当前item滚动的距离是否展示完全
    handleMoveHorizontaEnd() {
      let x = 0
      if (!this.isLft) { // 反方向排序都是正值
        x = this.translateX < 0 ? 0 : this.translateX
      } else {
        x = this.translateX > 0 ? 0 : Math.abs(this.translateX)
      }

      // 1.先找到当前滑动的item索引上
      // 2.在判断是往前还是往后移动一个
      if (this.slidesPerView !== 'auto') {
        let currentIndex = 0
        this.itemTranslateLists.some((item, index) => {
          if (item > x) return true
          currentIndex = index
        })
        this.slideChangeRuleWidth(currentIndex, x)
      } else {
        // todo: 不规则的item
      }
    },

    slideChangeRuleHeight(index, moveY) {
      let isBottomToTopMove = this.touch.getIsMoveTop()  // 获取当前滑动的方向

      const currentTranslateY = this.itemTranslateLists[index]

      const itemY = Math.abs(currentTranslateY - moveY) // 滚动的距离

      if (isBottomToTopMove) {
        if (itemY >= this.expectedDistance) {
          index += 1
        }
        this.slideTo(index, this.transitionDuration, true, this.onAutoPlayStart)
      } else {
        const afterTranslateY = this.itemTranslateLists[index + 1] - currentTranslateY
        const currentHeight = afterTranslateY - currentTranslateY
        if (currentHeight - itemY >= this.expectedDistance) {
          index -= 1
        }
        this.slideTo(index, this.transitionDuration, true, this.onAutoPlayStart)
      }
    },

    slideChangeRuleWidth(index, moveX) {
      let isRightToLeftMove = this.touch.getIsMoveLeft()  // 获取当前滑动的方向
      if (!this.isLft) {  // 反方向布局
        isRightToLeftMove = !isRightToLeftMove
      }

      const currentTranslateX = this.itemTranslateLists[index]
      const itemX = Math.abs(currentTranslateX - moveX) // 滚动的距离

      if (isRightToLeftMove) {
        if (itemX >= this.expectedDistance) {
          index += 1
        }
        this.slideTo(index, this.transitionDuration, true, this.onAutoPlayStart)
      } else {
        const afterTranslateX = this.itemTranslateLists[index + 1] - currentTranslateX
        const currentWidth = afterTranslateX - currentTranslateX
        if (currentWidth - itemX >= this.expectedDistance) {
          index -= 1
        }
        this.slideTo(index, this.transitionDuration, true, this.onAutoPlayStart)
      }
    },

    /* 初始化 */
    async handleInit() {
      if (this.initdFlag) return
      clearTimeout(this.delayLoopTimer)
      this.initdFlag = true
      this.swiperEl = this.$el
      this.wrapperEl = this.$el.querySelector('.slide-swiper-wrapper')
      this.handleLoopInit() // todo: 是否放在update之前，update会计算宽度。 后续验证
      await this.handleUpdate()
    },

    /**
     * 父容器调用
     * @param {*} options 
     */
    onUpdate() {
      if (!this.initdFlag) {
        return // 还没有触摸过，不需要更新
      }
      this.handleUpdate()
      this.handleDetectionLoop()
      this.handleLoopInit()
    },

    handleDetectionLoop() {
      // 判断是否之前有轮播，如果轮播将容器第一个和最后一个删除掉。
      // 清空之前的状态
      // 修复内容和loop动态变化情况下，数据问题
      if (this.loop && this.autoplayStart) {
        const slides = this.wrapperEl.children
        if (slides.length > this.itemLength) {
          this.wrapperEl.removeChild(slides[0])
          this.wrapperEl.removeChild(slides[slides.length - 1])
        }
        this.autoplayStart = null
        this.autoplayStop = null
      }
    },

    /**
     * 更新组件宽度
    */
    handleUpdate() {
      return new Promise((resolve) => {
        // 循环停止，二次开启循环就不需要执行
        const isLoopStop = this.loop || !this.disabledAutoPlay
        if(!isLoopStop) {
          this.translateX = 0
          this.translateY = 0
        }

        const handleDone = () => {
          this.newIndex = 0
          this.$emit('init', this.newIndex, { 
            slideTo: (...arg) => {
              this.slideTo(...arg)
            } 
          })
          resolve()
        }

        if (this.vertical) {
          this.handleVerticalHeight()
          handleDone()
        } else {
          this.$nextTick(()=> {
            this.wrapperWidth = this.wrapperEl.offsetWidth
            this.viewAreaWidth = this.swiperEl.offsetWidth
            if (this.wrapperWidth < this.viewAreaWidth) {
              // 解决当wrapper宽度小于可视化宽度时，滑动不了的问题
              this.viewAreaWidth = this.wrapperWidth
            }
            this.racewayDistanceWidth = this.wrapperWidth - this.viewAreaWidth
            this.hanldeItemWidth()
            this.$nextTick(() => {
              handleDone()
            })
          })
        }
      })
    },

    hanldeItemWidth() {
      if (this.slidesPerView !== 'auto') {
        if (!this.itemLength) {
          throw new Error('itemLength is empty')
        }
        const leng = this.loop ? this.itemLength + 2 : this.itemLength
        // 因为wrapperWidth的宽度包含了间距+轮播元素的宽度，在这里计算单个轮播元素宽度时需要处理下间距部分
        const itemWidth = (this.wrapperWidth - ((leng - 1) * this.spaceBetween)) / leng
        const list = [0]
        let distance = 0

        for (let i = 1; i < leng; i++) {
          distance += itemWidth + this.spaceBetween
          list.push(distance)
        }
        this.itemTranslateLists = list
      } else {
        // todo:
        // 每个item的宽度不规则的
        // 计算每个子元素的宽度， 进行增加，传递了间距的话，需要减去或者加上间距
        console.warn('slidesPerView is auto, 暂时不支持')
      }
    },

    handleVerticalHeight() {
      if (this.slidesPerView === 'auto') {
        // todo: 每个item的高度不规则的，后续开发
        console.warn('slidesPerView is auto, 暂时不支持')
      } else {
        const itemHeight = this.wrapperEl?.children?.[0]?.getBoundingClientRect?.().height
        if (!itemHeight && gbCommonInfo.isDebug) {
          return console.error('itemHeight is empty')
        }

        let distance = 0
        const listHeights = [0]

        const leng = this.loop ? this.itemLength + 2 : this.itemLength
        for (let i = 1; i < leng; i++) {
          distance += itemHeight
          listHeights.push(distance)
        }
        this.itemTranslateLists = listHeights

        const wrapperHeight = this.loop ? distance + itemHeight : distance

        this.viewAreaHeight = this.swiperEl.getBoundingClientRect().height
        if (this.wrapperHeight < this.viewAreaHeight) {   // 解决当wrapper高度小于可视化宽度时，滑动不了的问题
          this.viewAreaHeight = wrapperHeight
        }

        this.racewayDistanceHeight = wrapperHeight
        // this.racewayDistanceHeight = wrapperHeight - this.viewAreaHeight
      }
    },

    getDirectionLabel(property) {
      if (this.direction === 'ltr') {
        return property
      }

      return {
        'marginLeft': 'marginRight'
      }[property]
    },

    // 初始化滚动可视化监听实例
    initIntersectionObserverInstance() {
      if (!this.IntersectionObserverInstance) {
        this.IntersectionObserverInstance = new IntersectionObserver((entries) => {
          const intersectionRatio = entries[0].intersectionRatio
          
          if (intersectionRatio <= 0) {
            this.autoplayStop()
          } else if (intersectionRatio > 0) {
            this.autoplayStart({ type: 'observer' })
          }
        }, {
          thresholds: [0, 1]
        })
      }

      this.IntersectionObserverInstance.observe(this.$refs.slideSwiper)
    },
  },
  
  render() {
    return (
      <div 
        class="slide-swiper"
        ref="slideSwiper"
        vOn:touchstart={this.onTouchStart}
        vOn:touchend={ this.onTouchEnd }
        vOn:touchcancel={this.onTouchEnd}
        vOn:contextmenu_prevent={() => {}}
        vOn:click_prevent={(event) => { this.$emit('click', event) }}
      >
        <div 
          class="slide-swiper-wrapper"
          style={ this.wrapStyle }
        >
          { this.$slots.default }
        </div>
        { this.$slots.content }
      </div>
    )
  }
}
</script>

<style lang="less">
.slide-swiper {
  display: flex;
  // touch-action: pan-y;
  overflow: hidden;

  .slide-swiper-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
  }
}
</style>
